import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { UserSaveModel } from 'src/app/models/main.model';
@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html'
})

export class TopbarComponent implements OnInit {

  fullname: string = "";
  openMobileMenu: boolean;

  constructor(private router: Router) {
    this.fullname = localStorage.getItem("fullname");
  }

  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit() {
  }

  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }
}
