<div class="account-pages mt-5 mb-5">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8 col-lg-6 col-xl-5">
                <div class="card overflow-hidden">
                    <div class="bg-soft-primary">
                        <div class="row">
                            <div class="col-7">
                                <div class="text-primary p-4">
                                    <h5 class="text-primary">Welcome Back !</h5>
                                    <p>Sign in to continue to Admin.</p>
                                </div>
                            </div>
                            <div class="col-5 align-self-end">
                                <img src="assets/images/profile-img.png" alt="" class="img-fluid">
                            </div>
                        </div>
                    </div>
                    <div class="card-body pt-0">
                        <div class="p-2 pt-0 mt-0">
                            <div class="error-message" *ngIf="isLoginError">
                                Invalid login details.
                            </div>
                            <form class="form-horizontal" style="margin-top: 10px;">
                                <div class="form-group mb-3">
                                    <label for="username">Username</label>
                                    <input type="text" class="form-control" name="username" [(ngModel)]="loginRequestModel.username" placeholder="Username" required />
                                </div>

                                <div class="form-group mb-3">
                                    <label for="password">Password</label>
                                    <input type="password" class="form-control" name="password" [(ngModel)]="loginRequestModel.password" placeholder="Password" required />
                                </div>

                                <div class="mt-4">
                                    <button class="btn btn-primary btn-block" (click)="onSubmit()" type="button">Log
                                        In</button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- end container -->
</div>
<!-- end page -->