import { Injectable } from '@angular/core';

@Injectable()
export class GlobalService {

    public userStatus = ["ACTIVE", "INACTIVE"];
    public simCardStatus = ["IN-STOCK", "ALLOCATED", "INACTIVE","TRANSFER"];
    public smartPhoneStatus = ["IN-STOCK", "ALLOCATED", "DAMAGED", "LOST","TRANSFER"];
    public biometricStatus = ["IN-STOCK", "ALLOCATED", "DAMAGED", "LOST","TRANSFER"];

    public simCardCompany = ["Select","AIRTEL", "JIO", "BSNL", "VI"];
    public smartPhoneCompany = ["Select","Samsung J4", "Panasonic Eluga", "Moto C"];
    public biometricCompany = ["Select","Startek FM220", "Mantra MF100"];
}