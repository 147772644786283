export class LoginRequestModel {
    username: string;
    password: string;

    constructor(username?: string, id?: string, password?: string) {
        this.username = username;
        this.password = password;
    }
}

export class UserSaveModel {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    mobile: string;
}

export class AddBiometricModel {
    id: string;
    company: string;
    biometric_id: string;
    last_remark: string;
    status: string;
}

export class AddUserModel {
    id: string;
    ams_id: string;
    office_id: string;
    fullname: string;
    office_of_working: string;
    designation: string;
    mobile1: string;
    mobile2: string
    status:string;
}

export class AddSmartphoneModel {
    id: string;
    company: string;
    mdm_id: string;
    imei_1: string;
    imei_2: string;
    last_remark: string;
    status: string;
    lastupdate: string;
    createdon: string
}

export class AddSimcardModel {
    id: string;
    company: string;
    sim_number: string;
    mobile_no: string;
    last_remark: string;
    status: string;
    lastupdate: string;
    createdon: string;
}
export class AddAssetsMappingModel {
    id: string;
    asset_type: string;
    user_id: string;
    asset_id: string;
    issue_date: string;
    issue_remark: string;
}

export class EditAssetsMappingModel {
    id: string;
    asset_type: string;
    asset_id: string;
    return_date: string;
    return_remark: string;
    status: string;
}

export class LoginUpdateModel {
    id: string;
    username: string;
    password: string;
    fullname: string;
    mobile: string;
}