<footer class="footer">
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-2">
            <a [href]="uploadLink" target="_blank">Import Data</a>
        </div>
        <div class="col-sm-10">
            <div class="text-sm-right d-none d-sm-block">
                {{year}} © Sreyah Technologies | Helpline: 9049-49-4771 . support@sreyah.co.in . www.sreyah.co.in
            </div>
        </div>
    </div>
</div>
</footer>