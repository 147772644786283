<header id="page-topbar">
    <div class="navbar-header">
        <div class="d-flex">
            <!-- LOGO -->
            <div class="navbar-brand-box">
                <a class="logo logo-light">
                    <span class="logo-sm">
                        <img src="assets/images/small.png" alt="" height="25">
                    </span>
                    <span class="logo-lg">
                        <img src="assets/images/logo.jpg" alt="" height="40">
                    </span>
                </a>
            </div>
            <!-- App Search-->
        </div>
        <div class="d-flex">
            <div class="dropdown d-inline-block d-lg-none ml-2" >
                <button type="button" class="btn header-item noti-icon" id="page-header-search-dropdown" data-toggle="dropdown" >
                    <i class="mdi mdi-magnify"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0" >

                    <form class="p-3">
                        <div class="form-group m-0">
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Search ..." aria-label="Recipient's username">
                                <div class="input-group-append">
                                    <button class="btn btn-primary" type="submit"><i class="mdi mdi-magnify"></i></button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="dropdown d-inline-block" ngbDropdown>
            <button class="btn header-item dropdown-toggle" type="button" id="dropdownMenuButton"
              ngbDropdownToggle><img class="rounded-circle header-profile-user" src="\assets\images\users\male.png">
              <span class="d-none d-xl-inline-block ml-1">{{ fullname }}</span>
              <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
              <a class="dropdown-item" href="/profile">Profile</a>
              <a class="dropdown-item" href="/login">Logout</a>
            </div>
          </div>
    </div>

</header>