import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'Dashboard',
        icon: 'bx-home-circle',
        link: '/dashboard'
    },
    {
        id: 2,
        label: 'User',
        icon: 'bx-user-circle',
        link: '/user/list',
    },
    {
        id: 3,
        label: 'Smart Phone',
        icon: 'bx-mobile',
        link: '/smart-phone'
    },
    {
        id: 4,
        label: 'Biometric',
        icon: 'bx-devices',
        link: '/biometric'
    },
    {
        id: 5,
        label: 'SIM Card',
        icon: 'bx-memory-card',
        link: '/sim-card'
    },
];
