import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule, FormBuilder } from '@angular/forms';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbNavModule, NgbAccordionModule, NgbTooltipModule, NgbAlertModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutsModule } from './layouts/layouts.module';
import { AppComponent } from './app.component';

import { LayoutComponent } from './layouts/layout.component';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './ui/login/login.component';
import { HttpModule } from '@angular/http';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from "ngx-spinner";
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { MainService } from './services/main.service';
import { AuthGuard } from './core/guards/auth.guard';
import { GlobalService } from './services/global.service';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  {
    path: '', component: LayoutComponent,
    loadChildren: () => import('./ui/ui.routing.module').then(m => m.PagesModule),
    canActivate: [AuthGuard]
  },
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent
  ],
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' }),

    HttpModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LayoutsModule,
    NgbAccordionModule,
    NgbNavModule,
    NgbTooltipModule,
    NgbAlertModule,
    FormsModule,
    ToastrModule.forRoot({ positionClass: 'toast-bottom-right', timeOut: 10000, }),
    NgxSpinnerModule,
    NgbModule,
    Ng2SearchPipeModule
  ],
  bootstrap: [AppComponent],
  providers: [MainService, GlobalService],
})

export class AppModule { }
