import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginRequestModel } from 'src/app/models/main.model';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
})
export class LoginComponent implements OnInit {

  loginRequestModel = new LoginRequestModel();
  isLoginError: boolean = false;

  constructor(
    private mainService: MainService,
    private router: Router,
  ) { }

  ngOnInit() {
    localStorage.clear();
  }

  onSubmit() {
    this.mainService.login(this.loginRequestModel).subscribe((res) => {
      if (res['status'] == "100") {
        var fullname = res['data']['fullname'];
        
        localStorage.setItem("fullname", fullname);
        localStorage.setItem("userInfo", JSON.stringify(res['data']));

        this.router.navigate(['/dashboard']);
      } else {
        this.isLoginError = true;
      }
    }, error => {
      this.isLoginError = true;
    });
  }
}
