<header id="page-topbar">
    <div class="navbar-header">
        <div class="d-flex">
            <!-- LOGO -->
            <div class="navbar-brand-box">
                <!-- <a href="/" class="logo logo-dark">
                    <span class="logo-sm">
                    <img src="assets/images/logo.svg" style="height:50px">
                    </span>
                    <span class="logo-lg">
                    <img src="assets/images/logo-dark.png" style="height:50px">
                    </span>
                </a> -->

                <a href="/" class="logo logo-light">
                    <span class="logo-sm">
                        <img src="assets/images/logo.png" alt="" style="height:50px">
                    </span>
                    <span class="logo-lg">
                        <img src="assets/images/logo.png" alt="" style="height:50px">
                    </span>
                </a>
            </div>

            <button type="button" class="btn btn-sm px-3 font-size-16 d-lg-none header-item" (click)="toggleMenubar()">
                <i class="fa fa-fw fa-bars"></i>
            </button>

            <!-- App Search-->
            <form class="app-search d-none d-lg-block">
                <div class="position-relative">
                    <input type="text" class="form-control" placeholder="Search...">
                    <span class="bx bx-search-alt"></span>
                </div>
            </form>
        </div>

        <div class="d-flex">

            <div class="dropdown d-inline-block d-lg-none ml-2" ngbDropdown>
                <button type="button" class="btn header-item noti-icon" id="page-header-search-dropdown"
                    ngbDropdownToggle>
                    <i class="mdi mdi-magnify"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0" ngbDropdownMenu>

                    <form class="p-3">
                        <div class="form-group m-0">
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Search ..."
                                    aria-label="Recipient's username">
                                <div class="input-group-append">
                                    <button class="btn btn-primary" type="submit"><i
                                            class="mdi mdi-magnify"></i></button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>


            <div class="dropdown d-none d-lg-inline-block ml-1">
                <button type="button" class="btn header-item noti-icon" data-toggle="fullscreen" (click)="fullscreen()">
                    <i class="bx bx-fullscreen"></i>
                </button>
            </div>

            <div class="dropdown d-inline-block" ngbDropdown>
                <button type="button" class="btn header-item noti-icon" id="page-header-notifications-dropdown"
                    ngbDropdownToggle>
                    <i class="bx bx-bell bx-tada"></i>
                    <span class="badge badge-danger badge-pill">3</span>
                </button>
                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0" ngbDropdownMenu
                    aria-labelledby="page-header-notifications-dropdown">
                    <div class="p-3">
                        <div class="row align-items-center">
                            <div class="col">
                                <h6 class="m-0"> Notifications </h6>
                            </div>
                            <div class="col-auto">
                                <a href="#!" class="small"> View All</a>
                            </div>
                        </div>
                    </div>
                    <perfect-scrollbar style="height: 230px;" [config]="configData">
                        <a href="javascript: void(0);" class="text-reset notification-item">
                            <div class="media">
                                <div class="avatar-xs mr-3">
                                    <span class="avatar-title bg-primary rounded-circle font-size-16">
                                        <i class="bx bx-cart"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1">Your order is placed</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1">If several languages coalesce the grammar</p>
                                        <p class="mb-0"><i class="mdi mdi-clock-outline"></i> 3 min ago</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a href="javascript: void(0);" class="text-reset notification-item">
                            <div class="media">
                                <img src="assets/images/users/avatar-3.jpg" class="mr-3 rounded-circle avatar-xs"
                                    alt="user-pic">
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1">James Lemire</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1">It will seem like simplified English.</p>
                                        <p class="mb-0"><i class="mdi mdi-clock-outline"></i> 1 hours ago</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a href="javascript: void(0);" class="text-reset notification-item">
                            <div class="media">
                                <div class="avatar-xs mr-3">
                                    <span class="avatar-title bg-success rounded-circle font-size-16">
                                        <i class="bx bx-badge-check"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1">Your item is shipped</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1">If several languages coalesce the grammar</p>
                                        <p class="mb-0"><i class="mdi mdi-clock-outline"></i> 3 min ago</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a href="javascript: void(0);" class="text-reset notification-item">
                            <div class="media">
                                <img src="assets/images/users/avatar-4.jpg" class="mr-3 rounded-circle avatar-xs"
                                    alt="user-pic">
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1">Salena Layfield</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1">As a skeptical Cambridge friend of mine occidental.</p>
                                        <p class="mb-0"><i class="mdi mdi-clock-outline"></i> 1 hours ago</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </perfect-scrollbar>
                    <div class="p-2 border-top">
                        <a class="btn btn-sm btn-link font-size-14 btn-block text-center" href="javascript:void(0)">
                            <i class="mdi mdi-arrow-right-circle mr-1"></i> View More..
                        </a>
                    </div>
                </div>
            </div>

            <div class="dropdown d-inline-block" ngbDropdown>
                <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
                    <img class="rounded-circle header-profile-user" src="assets/images/users/avatar-1.jpg"
                        alt="Header Avatar">
                    <span class="d-none d-xl-inline-block ml-1">Henry</span>
                    <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                    <!-- item-->
                    <a class="dropdown-item" href="javascript: void(0);"><i
                            class="bx bx-user font-size-16 align-middle mr-1"></i>
                        Profile</a>
                    <a class="dropdown-item" href="javascript: void(0);"><i
                            class="bx bx-wallet font-size-16 align-middle mr-1"></i> My Wallet</a>
                    <a class="dropdown-item d-block" href="javascript: void(0);"><span
                            class="badge badge-success float-right">11</span><i
                            class="bx bx-wrench font-size-16 align-middle mr-1"></i> Settings</a>
                    <a class="dropdown-item" href="javascript: void(0);"><i
                            class="bx bx-lock-open font-size-16 align-middle mr-1"></i> Lock
                        screen</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
                            class=" bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i> Logout</a>
                </div>
            </div>

            <div class="dropdown d-inline-block">
                <button type="button" class="btn header-item noti-icon right-bar-toggle"
                    (click)="onSettingsButtonClicked()">
                    <i class="bx bx-cog bx-spin"></i>
                </button>
            </div>
        </div>
    </div>
</header>
<div class="topnav">
    <div class="container-fluid">
        <nav class="navbar navbar-light navbar-expand-lg topnav-menu active">
            <div class="collapse navbar-collapse active" id="topnav-menu-content">
                <ul class="navbar-nav">
                    <!-- Menu data -->
                    <ng-container *ngFor="let item of menuItems">
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle arrow-none" (click)="onMenuClick($event)"
                                href="javascript: void(0);" id="topnav-components" role="button">
                                <i class="bx bx-home-circle mr-2"></i>{{ item.label }}
                                <div class="arrow-down"></div>
                            </a>
                            <div class="dropdown-menu row" aria-labelledby="topnav-dashboard" *ngIf="hasItems(item)">
                                <ng-template ngFor let-i="index" let-subitem [ngForOf]="item.subItems">
                                    <a class="col dropdown-item side-nav-link-ref" *ngIf="!hasItems(subitem)"
                                        [routerLink]="subitem.link" routerLinkActive="active">{{subitem.label}}</a>

                                    <div class="dropdown" *ngIf="hasItems(subitem)">
                                        <a class="dropdown-item" (click)="onMenuClick($event)">{{ subitem.label }}
                                            <div class="arrow-down"></div>
                                        </a>
                                        <div class="dropdown-menu">
                                            <ng-template ngFor let-subSubitem [ngForOf]="subitem.subItems">
                                                <a class="dropdown-item side-nav-link-ref"
                                                    [routerLink]="subSubitem.link"
                                                    routerLinkActive="active">{{ subSubitem.label }}</a>
                                            </ng-template>
                                        </div>
                                    </div>
                                </ng-template>
                            </div>
                        </li>
                    </ng-container>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle arrow-none" href="javascript: void(0);" id="topnav-layout"
                            role="button" (click)="onMenuClick($event)">
                            <i class="bx bx-layout mr-2"></i>Layouts
                            <div class="arrow-down"></div>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="topnav-layout">
                            <a href="javascript: void(0);" class="dropdown-item"
                                (click)="changeLayout('vertical')">Vertical</a>
                            <a href="javascript: void(0);" class="dropdown-item" (click)="topbarLight()">Topbar
                                light</a>
                            <a href="javascript: void(0);" class="dropdown-item" (click)="boxedWidth()">Boxed width</a>
                            <a href="javascript: void(0);" class="dropdown-item" (click)="coloredHeader()">Colored
                                Header</a>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</div>