import { AddAssetsMappingModel, AddUserModel, EditAssetsMappingModel, LoginUpdateModel } from './../models/main.model';
import { AddSimcardModel } from './../models/main.model';
import { AddSmartphoneModel } from './../models/main.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AddBiometricModel, LoginRequestModel } from '../models/main.model';

@Injectable()
export class MainService {

    BASE_URL = environment.base_url;
    constructor(private http: HttpClient) { }

    login(loginRequestModel: LoginRequestModel) {
        const apiUrl = this.BASE_URL + "/login";
        return this.http.post(apiUrl, loginRequestModel);
    }

    updateLogin(loginUpdateModel: LoginUpdateModel) {
        const apiUrl = this.BASE_URL + "/loginUpdate";
        return this.http.post(apiUrl, loginUpdateModel);
    }

    getLoginSingle(loginRequestModel: LoginRequestModel) {
        const apiUrl = this.BASE_URL + "/login";
        return this.http.post(apiUrl, loginRequestModel);
    }

    dashboard() {
        const apiUrl = this.BASE_URL + "/dashboard";
        return this.http.get(apiUrl);
    }

    /// USER
    getListUser(requestModel) {
        const apiUrl = this.BASE_URL + "/userList";
        return this.http.post(apiUrl, requestModel);
    }

    getUserInfoSingle(userDetailsRequest) {
        const apiUrl = this.BASE_URL + "/userProfile";
        return this.http.post(apiUrl, userDetailsRequest);
    }

    addUser(addUserModel: AddUserModel) {
        const apiUrl = this.BASE_URL + "/userAdd";
        return this.http.post(apiUrl, addUserModel);
    }

    updateUser(addUserModel: AddUserModel) {
        const apiUrl = this.BASE_URL + "/userUpdate";
        return this.http.post(apiUrl, addUserModel);
    }

    /// BIMETRIC
    getListBimetric(requestModel) {
        const apiUrl = this.BASE_URL + "/biometricList";
        return this.http.post(apiUrl, requestModel);
    }

    addBiometric(addBiometricModel: AddBiometricModel) {
        const apiUrl = this.BASE_URL + "/biometricAdd";
        return this.http.post(apiUrl, addBiometricModel);
    }

    editBiometric(addBiometricModel: AddBiometricModel) {
        const apiUrl = this.BASE_URL + "/biometricEdit";
        return this.http.post(apiUrl, addBiometricModel);
    }

    /// SMART PHONE
    getListSmartphone(requestModel) {
        const apiUrl = this.BASE_URL + "/smartphoneList";
        return this.http.post(apiUrl, requestModel);
    }

    addSmartphone(addSmartphoneModel: AddSmartphoneModel) {
        const apiUrl = this.BASE_URL + "/smartphoneAdd";
        return this.http.post(apiUrl, addSmartphoneModel);
    }
    
    editSmartphone(addSmartphoneModel: AddSmartphoneModel) {
        const apiUrl = this.BASE_URL + "/smartPhoneUpdate";
        return this.http.post(apiUrl, addSmartphoneModel);
    }

    /// SIM CARD 
    getListSimcard(requestModel) {
        const apiUrl = this.BASE_URL + "/simcardList";
        return this.http.post(apiUrl, requestModel);
    }

    addSimcard(addSimcardModel: AddSimcardModel) {
        const apiUrl = this.BASE_URL + "/simcardAdd";
        return this.http.post(apiUrl, addSimcardModel);
    }

    editSimcard(addSimcardModel: AddSimcardModel) {
        const apiUrl = this.BASE_URL + "/simcardUpdate";
        return this.http.post(apiUrl, addSimcardModel);
    }

    /// ASSETS MAPPING
    searchDeviceData(searchDeviceData) {
        const apiUrl = this.BASE_URL + "/searchDeviceData";
        return this.http.post(apiUrl, searchDeviceData);
    }

    addAssetsMapping(addAssetsMappingModel: AddAssetsMappingModel) {
        const apiUrl = this.BASE_URL + "/addAssetsMapping";
        return this.http.post(apiUrl, addAssetsMappingModel);
    }

    updateAssetsMapping(addAssetsMappingModel: EditAssetsMappingModel) {
        const apiUrl = this.BASE_URL + "/updateAssetsMapping";
        return this.http.post(apiUrl, addAssetsMappingModel);
    }
}
