import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        var  fullname = localStorage.getItem('fullname')
        if(fullname == null) {
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            return false;
        } else {
            return true;
        }
    }
}